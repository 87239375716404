import React from 'react';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider, { ThemedGlobalStyle } from './theme';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { NetworkContextName } from './constants';
import getLibrary from 'utils/getLibrary';
import { ParallaxProvider } from 'react-scroll-parallax';
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if (!!(window as any).ethereum) {
  (window as any).ethereum.autoRefreshOnNetworkChange = false;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StyledEngineProvider injectFirst>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <ParallaxProvider>
          <ThemeProvider>
            <ThemedGlobalStyle />
            <App />
          </ThemeProvider>
        </ParallaxProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </StyledEngineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
