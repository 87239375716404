import { makeAutoObservable } from 'mobx';
export class Application {
  openConnectWallet: boolean = false;
  constructor() {
    makeAutoObservable<this>(this);
  }

  toggleConnectWalletModal = () => {
    this.openConnectWallet = !this.openConnectWallet;
  };
}

export default new Application();
