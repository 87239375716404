export const PolisApiHost = 'https://me.nuvosphere.io/';

export enum ChainId {
  mainnet = 1088,
  testnet = 599,
}

export enum Token {
  metis = 'metis',
  usdc = 'usdc',
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const Network = {
  Testnet: {
    name: 'Goerli',
    ChainId: ChainId.testnet,
    GatewayUrl: 'https://andromeda-service.metissafe.tech/cgw/v1',
    TransactionServiceUrl: 'https://goerli-txs.metissafe.tech/',
    TokenPriceHelpers: {
      nativeTokenAddress: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
      pricesApi: 'https://api.coingecko.com/api/v3/simple/token_price/',
    },
    nativeCoin: {
      address: '0x0000000000000000000000000000000000000000',
      name: 'tMetis',
      symbol: 'tMETIS',
      decimals: 18,
    },
  },
  Mainnet: {
    name: 'Andromeda',
    ChainId: ChainId.mainnet,
    GatewayUrl: 'https://andromeda-service.metissafe.tech/cgw/v1',
    TransactionServiceUrl: 'https://andromeda-service.metissafe.tech/txs/',
    TokenPriceHelpers: {
      nativeTokenAddress: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
      pricesApi: 'https://api.coingecko.com/api/v3/simple/token_price/',
    },
    nativeCoin: {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Metis',
      symbol: 'METIS',
      decimals: 18,
    },
  },
};

export const NetworkContextName = 'NETWORK';
