import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from 'pages/home';
import GlobalSimpleDialog from 'components/Dialog/GlobalSimpleDialog';
import { observer } from 'mobx-react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ConnectWalletModal from 'components/ConnectWalletModal';
import application from 'stores/application';
import Web3ReactManager from 'components/Web3ReactManager';
import { ParallaxProvider } from 'react-scroll-parallax';
// import { login } from 'common/auth';
// import { TYPE } from 'theme';
// import Button from 'components/Button';
// import user from 'stores/user';
// import dac from 'stores/dac';
// import storage from 'common/storage';
const useStorage = !!localStorage.useStorage;
const Wrapper = styled.div`
  width: 100%;

  margin: 0 auto;
  /* height: 100%; */
  /* background-image: url(${require('assets/img/home/bc-web.png')});
  background-size: 100% auto;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background-image: url(${require('assets/img/home/bc-mobile.png')});
  `} */
  overflow-x: hidden;
  box-sizing: border-box;

  padding: ${useStorage ? '20px' : '0'};
  background-color: #0f0f0f;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 0;
  `}
`;

// const StyledText = styled.input`
//   background-color: ${({ theme }) => theme.c.bg03};
//   min-height: 52px;
//   border-radius: 8px;
//   color: ${({ theme }) => theme.c.t01};
//   ::placeholder {
//     color: ${({ theme }) => theme.c.t02};
//     font-weight: 400;
//     font-size: 14px;
//   }
// `;

function App() {
  // const { id: dacId, setDacId } = dac;
  return (
    <Wrapper>
      <Header />
      <ParallaxProvider>
        <Router>
          <Web3ReactManager>
            <Switch>
              <Route exact path="/" component={Home} />
            </Switch>
          </Web3ReactManager>
        </Router>
      </ParallaxProvider>
      <Footer />
      <GlobalSimpleDialog />
      <ConnectWalletModal
        isOpen={application.openConnectWallet}
        onClose={application.toggleConnectWalletModal}
      />
    </Wrapper>
  );
}

export default observer(App);
