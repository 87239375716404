import Parallax from 'parallax-js';
import { generateStars } from './stars/stars';
import { generateCometaFromStar } from './cometa/cometa';

export default function (id: string) {
  const scene: any = document.getElementById(id);
  new Parallax(scene);
  const starsPosition = generateStars();

  for (let count = 0; count < 4; count++) {
    generateCometaFromStar(starsPosition);
  }
}
