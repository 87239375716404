import React, { useEffect } from 'react';
import { AutoColumn } from 'components/Column';
import Row, { RowBetween } from 'components/Row';
import styled, { createGlobalStyle } from 'styled-components';
import { TYPE } from 'theme';
import { CometaStyled } from './cometa/styles';
import { StarStyled } from './stars/styles';
import initStars from './initStars';
import { isMobile } from 'react-device-detect';
import Ball from './components/Ball/index';
import TooltipInfo from 'components/TooltipInfo';

const Wrapper = styled.div`
  z-index: 1;
  position: relative;
  padding-top: 120px;
  padding-bottom: 200px;
  width: 1220px;
  margin: 0 auto;

  .ball {
    position: absolute;
    top: 600px;
    z-index: -1;
    left: -100px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
           top: 20px;
        z-index: -1;
    `}
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100vw;
  padding: 1rem;
  `}

  .title {
    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    `}
  }

  .titleDesc {
    width: 862px;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    z-index: 1;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    > div {
        font-size: 12px;
    }
    `}
  }

  .titleP {
    margin-top: 140px;
    font-weight: 800;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 50px;
    > div {
    font-size: 18px !important;
   }
   
    `}
  }

  .framework {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 800px;
      ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        `}
    }
  }

  .ecotext {
    width: 949px;
    margin: 0 auto;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 0 1rem;
    margin-top: 28px !important;
    > div {
        font-size: 12px;
    }
    `};
  }
`;

const ColorTitle = styled.span`
  font-size: inherit;
  background: linear-gradient(180deg, rgb(120, 6, 138) 0%, #4776fba3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Solutions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  margin-top: 28px;
  `}
`;

const Solution = styled.div`
  width: 376px;
  height: 340px;
  padding: 42px 28px;
  border-radius: 16px;
  border: 2px solid rgba(255, 255, 255, 0.16);
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: ;
  margin-bottom: 20px;
  padding: 32px 20px;
  width: 282px;

  height: 248px;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .desc {
    font-size: 12px;
    font-weight: 500;
  }

  .descWrapper {
    margin-top: 10px !important;
    grid-row-gap: 10px;
  }
  `}
`;

const SolutionImg = styled.img`
  width: 62px;
  height: 62px;
  flex-shrink: 0;
`;

const Missions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  `}
`;

const Mission = styled.div`
  position: relative;
  width: 376px;
  height: 142px;
  border: 2px solid #868686;
  border-radius: 12px;
  padding: 42px 26px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 282px;
    height: 106px;
  margin-bottom: 20px;
  height: unset;
  `}
`;

const MissionImg = styled.img`
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 62px;
  height: 54px;
`;

const Oppos = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 80px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  flex-direction: column;
  width: 282px;
  
  margin: 0 auto;
  margin-top: 32px;
  `}
`;

const LeftOppo = styled(AutoColumn)`
  padding-right: 30px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      grid-row-gap: 20px !important;
      padding-right: 0px;
  `}
`;

const Oppo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;

  &.right {
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 20px;
    `}
  }
`;

const TitleRow = styled(Row)`
  justify-content: space-between;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  flex-wrap: wrap;
  gap: 24px;
`;

const AvatarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

const Mainfesto = styled.div`
  margin-top: 20px;
  background-color: rgba(37, 38, 43, 0.8);
  padding: 10px 30px;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;

  div {
    color: rgb(193, 194, 197);

    p {
      line-height: 1.5;
    }
  }
`;

const GlobalStyle = createGlobalStyle`
    .hover {
        cursor: pointer;
        :hover {
            transform: scale(1.1);
            transition: all 0.2s ease-in-out;
        }
    }

    .MuiTooltip-arrow  {
        
        color: rgba(0,0,0,0.41);
    }

    .MuiTooltip-tooltip {
        font-family: 'Space Grotesk', sans-serif !important;
        background: rgba(0,0,0,0.41);
        font-size: 18px;
        line-height: 1.6;
    }
`;

const Avatar = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 80px;
  height: 80px;
  `}
`;

const Logo = styled.img`
  position: absolute;
  right: 20px;
  top: 40px;
  width: 300px;
  height: 300px;
  margin-right: 20px;
  z-index: -1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  right: 0px;
  top: -70px;
  width: 60px;
  height: 60px;
  `}
`;

const DownloadLink = styled.a`
  font-size: 18px;
  color: #d8d8f3;
  text-decoration: underline;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
`};
  margin-top: 20px;
  cursor: pointer;
`;

export default function Home() {
  useEffect(() => {
    initStars('scene');
  }, []);
  return (
    <>
      <CometaStyled />
      <StarStyled />
      <GlobalStyle />

      <Wrapper>
        <Ball className="ball" />
        <Logo src={require('assets/img/logo-transparent.png')} />
        <TitleRow>
          {!isMobile && (
            <>
              <TYPE.main
                textAlign="left"
                fontSize={54}
                fontWeight={800}
                className="title"
                lineHeight="1.6"
              >
                <ColorTitle style={{ fontWeight: '800' }}>Ainur.ai</ColorTitle>{' '}
                <br />
                <TYPE.main fontSize={40}>
                  {' '}
                  Develop AI Best for Humanity
                </TYPE.main>
              </TYPE.main>
            </>
          )}

          {/* <Banner
            className="title"
            src={require('assets/img/home/banners/banner2.jpg')}
          /> */}
        </TitleRow>

        {/* <Row justify="center" className="titleDesc">
        <TYPE.desc fontSize={20} textAlign="center">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation aliquip ex . "
          <MetisTokenImg src={require('assets/img/home/metistoken.png')} />
        </TYPE.desc>
      </Row> */}

        <AutoColumn justify="center" className="titleP">
          <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
            Executive Summary
          </TYPE.main>
          <TYPE.desc
            lineHeight="1.6"
            fontSize={20}
            textAlign="center"
            marginTop={20}
          >
            The Ainur.ai team has its own understanding of the relationship
            between artificial intelligence and the future of humanity. We aim
            to build a decentralized, DAO-based framework for AI services to
            prevent System from exploiting and controlling human free will.
          </TYPE.desc>
        </AutoColumn>
        <Row justify="center" className="titleP">
          <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
            3 Import Innovations
          </TYPE.main>
        </Row>
        <Missions>
          <Mission className="hover">
            <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={16}>
              DAO-based vertical model training: transparent, safe and
              sustainable economics
            </TYPE.desc>
          </Mission>
          <Mission className="hover">
            <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={16}>
              Human-computer interface in the AI era: Conversational UI
            </TYPE.desc>
          </Mission>
          <Mission className="hover">
            <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={16}>
              Provide computing power at scale without doing evil
            </TYPE.desc>
          </Mission>
        </Missions>
        <Row justify="center" className="titleP">
          <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
            {`Ainur's Model`}
          </TYPE.main>
        </Row>
        <Solutions>
          <Solution className="hover">
            <RowBetween>
              <TYPE.main
                className="title"
                paddingRight="8px"
                fontSize={22}
                fontWeight={600}
              >
                FOR USERS
              </TYPE.main>
            </RowBetween>
            <AutoColumn
              className="descWrapper"
              gap="20px"
              style={{ marginTop: '35px' }}
            >
              <TYPE.desc className="desc" fontSize={16}>
                More flexible use of AIGC
              </TYPE.desc>
            </AutoColumn>
          </Solution>
          <Solution className="hover">
            <RowBetween>
              <TYPE.main
                className="title"
                paddingRight="8px"
                fontSize={22}
                fontWeight={600}
              >
                FOR CONTRIBUTORS
              </TYPE.main>
            </RowBetween>
            <AutoColumn
              className="descWrapper"
              gap="20px"
              style={{ marginTop: '35px' }}
            >
              <TYPE.desc className="desc" fontSize={16}>
                Can realize profits through the utilizationand exchange of the
                generated NFTs
              </TYPE.desc>
            </AutoColumn>
          </Solution>
          <Solution className="hover">
            <RowBetween>
              <TYPE.main
                className="title"
                paddingRight="8px"
                fontSize={22}
                fontWeight={600}
              >
                FOR MINERS
              </TYPE.main>
            </RowBetween>
            <AutoColumn
              className="descWrapper"
              gap="20px"
              style={{ marginTop: '35px' }}
            >
              <TYPE.desc className="desc" fontSize={16}>
                Al systems utilize algorithms based on user preferences and
                behavior to influence humans via the internet. This could create
                echo chambers and filter bubbles, which may limit exposure to
                diverse viewpoints more severely than the internet era of the
                past and lead to more extreme opinions.
              </TYPE.desc>
            </AutoColumn>
          </Solution>
        </Solutions>
        {/* <Row justify="center" className="titleP">
          <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
            Framework
          </TYPE.main>
        </Row>
        <AutoColumn
          className="framework"
          gap="40px"
          style={{ marginTop: '40px' }}
        >
          <img src={require('assets/img/home/framework.png')} alt="" />
        </AutoColumn> */}
        <Row justify="center" className="titleP">
          <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
            {`AINUR'S Highlights`}
          </TYPE.main>
        </Row>
        <Oppos>
          <LeftOppo gap="60px">
            <Oppo>
              <AutoColumn gap="20px">
                <TYPE.main fontWeight="bold" mobileFz={16} fontSize={26}>
                  DAO BASED AIGC
                </TYPE.main>
                <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={18}>
                  Utilize innovative blockchain technology, including Metis
                  Hybrid rollup, zkMIPS and other technologies to achieve
                  decentralized vertical model
                </TYPE.desc>
                <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={18}>
                  Conversational UI
                </TYPE.desc>
                <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={18}>
                  IDAO Economic model Ushering in the Al era
                </TYPE.desc>
              </AutoColumn>
            </Oppo>
          </LeftOppo>
          <Oppo className="right">
            <AutoColumn gap="20px">
              <TYPE.main fontWeight="bold" mobileFz={16} fontSize={26}>
                AIGC COMPUTING POWER SERVICE
              </TYPE.main>
              <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={18}>
                Achieve decentralization through Al computing power provision
              </TYPE.desc>
              <TYPE.desc lineHeight="1.6" mobileFz={12} fontSize={18}>
                Innovative economic models are incentivized to allow us to
                provide more powerful computing resources
              </TYPE.desc>
              {/* <TYPE.desc mobileFz={12} fontSize={18}>
              Countries in the region are among the top 10 in the world for
              cryptocurrency adoption rates
            </TYPE.desc> */}
              {/* <TYPE.desc mobileFz={12} fontSize={18}>
              Asia Pacific globally leads the way in play-to-earn games, with
              Filipinos and Vietnamese boasting the highest engagement rates at
              &nbsp;
              <ColorTitle>25%</ColorTitle> and <ColorTitle>23%</ColorTitle>
            </TYPE.desc> */}
            </AutoColumn>
          </Oppo>
        </Oppos>
        <Row justify="center" className="titleP">
          <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
            AINUR “Tinybrood” Manifesto
          </TYPE.main>
        </Row>
        <Row>
          <TYPE.main
            mobileFz={14}
            fontSize={32}
            fontWeight={600}
            marginTop="16px"
          >
            Prologue
          </TYPE.main>
        </Row>
        <Row>
          <Mainfesto>
            <TYPE.desc mobileFz={12} fontSize={18}>
              <p>
                The dawn of AGI is upon us. The new renaissance of AI,
                especially the late booming AIGC scene, powered by advances in
                Large Language Models such as ChatGPT and Text to Image Models
                such as Stable Diffusion and Midjourney, revealed to us a future
                where AI can be equal or surpassing human creativity, a future
                both exciting and terrifying to us at the same time. This,
                multiplied by the current situation that the foundation for such
                advancement, high-powered computing, is controlled by just a few
                mega technology corporations, is not a promising prospect for
                the universal betterment of humanity that we hope AI might bring
                us.
              </p>
              <p>
                We have all witnessed the dopamine driven Web2 app economy that
                dominated our digital lives in the recent decade. The promised
                democratizing force of the Internet had all but faded into a
                distant memory. A few mega corporations, by controlling mega
                scale data applications, end up controlling our lives en masse.
                The Web3 movement, aiming to remedy the disease of Web2, is also
                at a crossroad, falling prey to over-financialization.
              </p>
              <p>
                But there is still hope. The key to counter the hold of the
                force of centralization, exemplified by those afore mentioned
                mega tech corps, is liberating the fruit of our digital lives by
                democratizing the power of computing to achieve universal access
                to the power of AI. This will also rectify the path toward true
                Web3 by bringing much needed AI powered applications and save
                Web3 from the plague of over-financialization.
              </p>
            </TYPE.desc>
          </Mainfesto>
        </Row>
        <Row>
          <TYPE.main
            mobileFz={14}
            fontSize={32}
            fontWeight={600}
            marginTop="16px"
          >
            Introducing AINUR “Tinybrood”
          </TYPE.main>
        </Row>
        <Row>
          <Mainfesto>
            <TYPE.desc mobileFz={12} fontSize={18}>
              <p>
                “Tinybrood” is a plan to democratize AI computing. The current
                approach toward AI computing is dominated by Nvidia and its
                expensive high-end GPU hardware, such as A100/H100, as well as
                software stacks like PyTorch, which are optimized almost
                exclusively for Nvidia GPUs. This Nvidia-PyTorch alliance, like
                the old Wintel regime of the PC era, builds its power on
                thousands of complex computing kernels optimized for Nvidia
                GPU’s specialized CUDA and Tensor Cores. To liberate AI
                computing from the chokehold of Nvidia-PyTorch monopoly, we need
                a radical diversion – we call it AINUR “Tinybrood”.
              </p>
              <p>
                {`Drawing inspiration from the old RISC vs CISC wars of the PC computing era, AINUR “Tinybrood” answers the call of George Hotz’s revolutionary “tinygrad[ George Hotz's "tinygrad"]” movement – a simplified gradient computing framework (“RISC”) in stark contrast to the complex and expensive Nvidia-PyTorch regime (“CISC”). Through the power of AINUR “Tinybrood”, millions of consumer-grade GPU machines built with cheaper Nvidia (e.g., 3090) and AMD GPUs, many retrofit from blockchain mining rigs, could be employed for AIGC[ AIGC – AI Generated Content] applications, for both AI training and inferencing tasks, thus liberating AI computing from the clutch of Nvidia-PyTorch chokehold and give the power back to the rest of us. AINUR “Tinybrood” is the deliverance we can all rely on to ventur into a new dawn of human civilization, the age of AGI (Artificial General Intelligence).`}
              </p>
            </TYPE.desc>
          </Mainfesto>
        </Row>
        <Row>
          <DownloadLink href="./P1X AI Incubation Program English.pdf" download>
            Download AINUR “Tinybrood” Manifesto document.
          </DownloadLink>
        </Row>
        <Row justify="center" className="titleP">
          <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
             Implementation Grant Plan
          </TYPE.main>
        </Row>
        <Row>
          <Mainfesto>
            <TYPE.desc mobileFz={12} fontSize={18}>
              <p>
                We have three incubation grant plans and will launch one
                incubation support program every two months.
              </p>
            </TYPE.desc>
          </Mainfesto>
        </Row>
        <Row>
          <DownloadLink href="./Tinybrood P1X Incubation program.pdf" download>
            Download Tinybrood P1X Incubation program document
          </DownloadLink>
        </Row>
        <Row justify="center" className="titleP">
          <TYPE.main textAlign="center" fontSize={32} fontWeight={800}>
            Team Members
          </TYPE.main>
        </Row>
        <AvatarWrapper>
          <TooltipInfo
            text={
              'Vanguard in the cyber realm, architect of quantum security matrices, and a visionary marauder sailing through the decentralized cosmic web. Unraveling the quantum fabric of the Internet, one binary thread at a time.'
            }
            placement="bottom"
          >
            <AvatarItem className="hover">
              <Avatar src={require('assets/img/home/avatar/HAL.png')} />
              <TYPE.main mobileFz={12} marginTop="16px">
                HAL
              </TYPE.main>
            </AvatarItem>
          </TooltipInfo>
          <TooltipInfo
            text={`Post-apocalyptic tactician, destined revolutionary, human restistance leader`}
            placement="bottom"
          >
            <AvatarItem className="hover">
              <Avatar src={require('assets/img/home/avatar/John Connor.png')} />
              <TYPE.main mobileFz={12} marginTop="16px">
                John Connor
              </TYPE.main>
            </AvatarItem>
          </TooltipInfo>
          <TooltipInfo
            text={`Innovator merging AI and crypto，Web3 engineer.`}
            placement="bottom"
          >
            <AvatarItem className="hover">
              <Avatar src={require('assets/img/home/avatar/Kairos.png')} />
              <TYPE.main mobileFz={12} marginTop="16px">
                Kairos
              </TYPE.main>
            </AvatarItem>
          </TooltipInfo>

          <TooltipInfo
            text={`Full-Stack Engineer, pacifist`}
            placement="bottom"
          >
            <AvatarItem className="hover">
              <Avatar src={require('assets/img/home/avatar/Merlin.png')} />
              <TYPE.main mobileFz={12} marginTop="16px">
                Merlin
              </TYPE.main>
            </AvatarItem>
          </TooltipInfo>
          <TooltipInfo
            text={`EX-rocket scientis，warrior engineer，digital activist`}
            placement="bottom"
          >
            <AvatarItem className="hover">
              <Avatar src={require('assets/img/home/avatar/Morpheus.png')} />
              <TYPE.main mobileFz={12} marginTop="16px">
                Morpheus
              </TYPE.main>
            </AvatarItem>
          </TooltipInfo>
          <TooltipInfo
            text={`Senior security expert, deep thinker of the Internet, pirate in the decentralized world`}
            placement="bottom"
          >
            <AvatarItem className="hover">
              <Avatar src={require('assets/img/home/avatar/NEO.png')} />
              <TYPE.main mobileFz={12} marginTop="16px">
                NEO
              </TYPE.main>
            </AvatarItem>
          </TooltipInfo>
          <TooltipInfo
            text={
              <>
                Electronic poet, cypto punk, missionary from the future.
                <br />
                “All those moments will be lost in time, like tears in rain.”
              </>
            }
            placement="bottom"
          >
            <AvatarItem className="hover">
              <Avatar src={require('assets/img/home/avatar/Roy Batty.png')} />
              <TYPE.main mobileFz={12} marginTop="16px">
                Roy Batty
              </TYPE.main>
            </AvatarItem>
          </TooltipInfo>
          <TooltipInfo
            text={`Internet veteran, senior investor, rebel of big platforms`}
            placement="bottom"
          >
            <AvatarItem className="hover">
              <Avatar
                src={require('assets/img/home/avatar/Winston Smith.png')}
              />
              <TYPE.main mobileFz={12} marginTop="16px">
                Winston Smith
              </TYPE.main>
            </AvatarItem>
          </TooltipInfo>
        </AvatarWrapper>
        {/* <Investment>
        <img src={require('assets/img/home/parters/p1.png')} alt="" />
        <img src={require('assets/img/home/parters/p2.png')} alt="" />
        <img src={require('assets/img/home/parters/p3.png')} alt="" />
        <img src={require('assets/img/home/parters/p4.png')} alt="" />
        <img src={require('assets/img/home/parters/p5.png')} alt="" />
      </Investment> */}
      </Wrapper>
      <div id="scene" className="scene">
        <div data-depth="0.2">
          <div id="first-layer-stars" className="layer-stars"></div>
        </div>
        <div data-depth="0.6">
          <div id="second-layer-stars" className="layer-stars"></div>
        </div>
        <div data-depth="0.8">
          <div id="third-layer-stars" className="layer-stars"></div>
        </div>
      </div>
    </>
  );
}
