import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Wrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 60px;
  position: relative;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 6px 20px;
  `}
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  `}
`;
const TitleWrapper = styled.div`
  color: #fff;
  font-size: 30px;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: column;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    `}
`;
export default function Header() {
  return (
    <Wrapper>
      <TitleWrapper>
        <TYPE.main fontSize={40}>Ainur.ai</TYPE.main>
        <TYPE.desc marginLeft="4px">Develop AI Best for Humanity</TYPE.desc>
      </TitleWrapper>

      {/* <LinkWrapper>
        <TYPE.main>Our Solutions</TYPE.main>
        <TYPE.main marginLeft={20}>APAC Opportunity</TYPE.main>
      </LinkWrapper> */}
    </Wrapper>
  );
}
