/* eslint-disable */
import { maxStarsPosition } from '../constants';

export const generateStars = function () {
  const firstLayer: any = document.getElementById('first-layer-stars');
  const secondLayer: any = document.getElementById('second-layer-stars');
  const thirdLayer: any = document.getElementById('third-layer-stars');

  let iterator = 0;
  const starsPosition = [];
  while (iterator <= maxStarsPosition) {
    const xpositionStar1 = Math.random();
    const ypositionStar1 = Math.random();
    const xpositionStar2 = Math.random();
    const ypositionStar2 = Math.random();
    const xpositionStar3 = Math.random();
    const ypositionStar3 = Math.random();

    const positionForStar1 = {
      x: Math.floor(window.innerWidth * xpositionStar1),
      y: Math.floor(window.innerHeight * ypositionStar1),
    };
    const positionForStar2 = {
      x: Math.floor(window.innerWidth * xpositionStar2),
      y: Math.floor(window.innerHeight * ypositionStar2),
    };
    const positionForStar3 = {
      x: Math.floor(window.innerWidth * xpositionStar3),
      y: Math.floor(window.innerHeight * ypositionStar3),
    };

    const starType1 = document.createElement('div');
    starType1.classList.add('star-type1');
    if (iterator % 3 === 0) {
      starType1.classList.add('animated');
    }
    starType1.classList.add(`${iterator}`);
    starType1.style.cssText =
      'top:' +
      positionForStar1.y +
      'px;left:' +
      positionForStar1.x +
      'px;background: white;';
    firstLayer.appendChild(starType1);

    const starType2 = document.createElement('div');
    starType2.classList.add('star-type2');
    starType2.classList.add(`${iterator}`);
    starType2.style.cssText =
      'top:' +
      positionForStar2.y +
      'px;left:' +
      positionForStar2.x +
      'px;background: pink;';
    secondLayer.appendChild(starType2);

    const starType3 = document.createElement('div');
    starType3.classList.add('star-type3');
    starType3.classList.add(`${iterator}`);
    starType3.style.cssText =
      'top:' +
      positionForStar3.y +
      'px;left:' +
      positionForStar3.x +
      'px;background: white;';
    thirdLayer.appendChild(starType3);

    iterator++;
    starsPosition.push(positionForStar1);
  }

  return starsPosition;
};

export const animateStars = function (
  numberOfStarsToAnimate: any,
  numberOfLayer: any,
) {};
