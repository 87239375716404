import React, { useState } from 'react';
import cx from 'classnames';
import Row from 'components/Row';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { SUPPORTED_WALLETS } from 'connectors';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

const Content = styled.div`
  /* width: 460px; */
  box-sizing: border-box;
  /* padding: 10px 30px; */

  .nextButton {
    width: 80%;
  }

  .walletsList {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 18px;
    /* margin-top: 30px; */

    .item {
      width: 400px;
      height: 66px;
      margin-top: 12px;
      background-color: #0f0f0f;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      box-sizing: border-box;
      padding: 0 10px;

      .icon {
        width: 32px;
        height: 32px;
        margin-left: 10px;
        background-size: 100%;
      }

      &.installMetaMask {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        .metaMask {
          width: 32px;
          height: 32px;
          background-image: url(${require('assets/img/metamask-icon.png')})
            no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          margin: 8px 0;
        }
      }

      &.metamask {
        .icon {
          width: 32px;
          height: 32px;
          background: url(${require('assets/img/metamask-icon.png')}) no-repeat;
          background-size: 100%;
          background-position: center center;
        }
      }
      // &.walletConnect {
      //     background: url(../../assets/images/walletconnect.png) no-repeat;
      //     background-size: 208px 34px;
      //     background-position: center center;
      // }
    }
  }

  .warning {
    line-height: 1.7;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 100%;
        .walletsList {
            .item {
                width: 100%;
            }
        }
    `}
`;

interface Props {
  isOpen: boolean;
  onClose(): void;
}

export default function ConnectWalletModal({ isOpen, onClose }: Props) {
  const { active, account, connector, activate, error } = useWeb3React();
  console.log(active, account, connector);
  const [pendingError, setPendingError] = useState<boolean>();
  const metaMaskNotInstall = !(
    (window as any).web3 ||
    ((window as any).ethereum && !(window as any).ethereum.isMetaMask)
  );

  const tryActivation = async (connector: AbstractConnector | undefined) => {
    let name = '';
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name);
      }
      return true;
    });
    // setPendingWallet(connector) // set wallet for pending view
    // setWalletView(WALLET_VIEWS.PENDING)

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.wc?.uri
    ) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, true).catch((error) => {
        if (error instanceof UnsupportedChainIdError) {
          activate(connector); // a little janky...can't use setError because the connector isn't set
        } else {
          setPendingError(true);
        }
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      // customContentClass={style.modalContent}
    >
      <Content>
        <Row justify="center">
          <TYPE.main fontSize={16} fontWeight="bold">
            Connect your wallet
          </TYPE.main>
        </Row>
        <div className={'walletsList'}>
          <div
            className={cx('item', 'metamask')}
            onClick={() => {
              if (metaMaskNotInstall) {
                window.open('https://metamask.io/');
              } else {
                tryActivation(SUPPORTED_WALLETS['METAMASK'].connector);
                // handleClick('injected');
              }
            }}
          >
            <TYPE.main fontSize={14}>
              {metaMaskNotInstall ? 'Install Metamask' : 'Connect by Metamask'}
            </TYPE.main>
            <div className="icon" />
          </div>
        </div>
      </Content>
    </Modal>
  );
}
