import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;

const Wrapper = styled.div`
  background-color: transparent;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 0 2rem 0;
  color: #fff;
  background-color: #262323;
  padding: 0px;
  /* width: 50vw; */
  max-width: 1200px;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 1rem;

  align-self: center;

  display: flex;
  border-radius: 8px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
       width:  90vw;
      margin: 0;
    `}
`;

interface Props {
  isOpen: boolean;
  onClose(): void;
  children?: React.ReactNode;
  className?: string;
}

export default function TransitionsModal({
  isOpen,
  onClose,
  children,
  className,
}: Props) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Wrapper>
          <Content className={className}>
            <StyledCloseIcon onClick={onClose} className="closeIcon" />
            {children}
          </Content>
        </Wrapper>
      </Fade>
    </Modal>
  );
}
