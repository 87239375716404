import { createGlobalStyle } from 'styled-components';
export const StarStyled = createGlobalStyle`
    .scene {
        width: 100vw;
        height: 100vh;
        z-index: 0;
        top:0;
        position: fixed;
        background: radial-gradient(ellipse at top, #24122b, transparent),
            radial-gradient(ellipse at bottom, #0b0a38, transparent),
            linear-gradient(45deg, rgba(3, 31, 110, 0.637), rgb(0, 0, 0)),
            radial-gradient(ellipse at center, transparent, rgb(120, 6, 138));

            .layer-stars {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;

            .star-type1, .star-type2, .star-type3 {
                width: 1px;
                height: 1px;
                position: absolute;
                border-radius: 1px;
                -webkit-box-shadow: 0 0 0.5px 0.5px rgba(255, 255, 255, 0.5);
                box-shadow: 0 0 0.5px 0.5px rgba(255, 255, 255, 0.5);
            }
            .animated {
                -webkit-animation: twinkle 5s ease-in-out infinite;
                -moz-animation: twinkle 5s ease-in-out infinite;
                -o-animation: twinkle 5s ease-in-out infinite;
                animation: twinkle 5s ease-in-out infinite;
            }
            }
        }

        ::-webkit-scrollbar {
        display: none;
        }

        @-webkit-keyframes twinkle {
        0%   { box-shadow: 0 0 1px 1px white; }
        30%  { box-shadow: 0 0 1px 1px white; }
        70%  { box-shadow: 0 0 0.5px 0.5px rgba(255, 255, 255, 0.5); }
        100% { box-shadow: 0 0 1px 1px white; }
        }
        @-moz-keyframes twinkle {
        0%   { box-shadow: 0 0 1px 1px white; }
        30%  { box-shadow: 0 0 1px 1px white; }
        70%  { box-shadow: 0 0 0.5px 0.5px rgba(255, 255, 255, 0.5); }
        100% { box-shadow: 0 0 1px 1px white; }
        }
        @-o-keyframes twinkle {
        0%   { box-shadow: 0 0 1px 1px white; }
        30%  { box-shadow: 0 0 1px 1px white; }
        70%  { box-shadow: 0 0 0.5px 0.5px rgba(255, 255, 255, 0.5); }
        100% { box-shadow: 0 0 1px 1px white; }
        }
        @keyframes twinkle {
        0%   { box-shadow: 0 0 1px 1px white; }
        30%  { box-shadow: 0 0 1px 1px white; }
        70%  { box-shadow: 0 0 0.5px 0.5px rgba(255, 255, 255, 0.5); }
        100% { box-shadow: 0 0 1px 1px white; }
        }
`;
